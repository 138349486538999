import React from "react";
import store from "../../../Store";
import { Link } from "react-router-dom";
import { toggleSiteListFilter } from "./SiteList-Actions";
import { withRouter } from "react-router";
import { connect } from "react-redux";

class SiteHeading extends React.Component {
  componentDidMount() {}

  toggleFilter = (e) => {
    store.dispatch(toggleSiteListFilter(e, this.props.organization_id));
  };

  render() {
    let { organization_id, organization, params } = this.props;

    return (
      <div className="heading-block pr-60">
        <div className="title-block">
          <h2>Sites</h2>
          <span>
            {" "}
            <Link to="/organizations">Organizations</Link>
          </span>
          <span>{organization}</span>
        </div>
        <div className="search-bar">
          <input
            type="text"
            className="form-control"
            name="q"
            placeholder={`Search Sites`}
            value={params.q}
            onChange={this.toggleFilter}
          />
          <span className="icon-search" />
        </div>
        <div className="button-wrapper d-flex">
          <Link
            to={`/organizations/${organization_id}/sites/create`}
            className="btn-theme btn-dark-blue mr-2"
          >
            Add Site
          </Link>
          <Link
            to={`/organizations/${organization_id}/update`}
            className="btn-theme btn-dark-blue"
          >
            Edit Organization
          </Link>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { getSites, siteList, BreadCrumb, getOrganization } = state;

  let organization =
    getOrganization && getOrganization.success && getOrganization.success.name;

  let { params } = siteList.data;

  return {
    getSites,
    siteList,
    params,
    BreadCrumb,
    getOrganization,
    organization,
  };
}
export default withRouter(connect(mapStateToProps)(SiteHeading));
