import store from "../../../Store";
import { toggleURLQuery } from "../../../helpers/UrlHelper";
import getOrganizations from "../../../ajax/actions/organization/organizations_get";

export function toggleOrganizationListFilter(e, history) {
  return function (dispatch) {
    let { name, value } = e.target;
    toggleURLQuery(name, value, history);
    dispatch({ type: "TOGGLE-ORGANIZATION-LIST-FILTER", name, value, history });
    if (name === "limit") {
      store.dispatch(getOrganizations());
      toggleURLQuery("page", "", history);
    } else {
      store.dispatch(getOrganizations());
    }
  };
}

export function onBillableChange(value, history) {
  return function (dispatch) {
    let name = "billable";
    if (value === 0) {
      toggleURLQuery(name, "inactive", history);
    }
    if (value === 1) {
      toggleURLQuery(name, "active", history);
    }
    toggleURLQuery("page", 1, history);
    dispatch({ type: "ON-ORGANIZATION-BILLABLE-CHANGE", name, value, history });
    if (name === "limit") {
      store.dispatch(getOrganizations());
      toggleURLQuery("page", "", history);
    } else {
      store.dispatch(getOrganizations());
    }
  };
}

export function defaultPage(history) {
  return function (dispatch) {
    toggleURLQuery("page", 1, history);
    dispatch({ type: "ON-DEFAULT-PAGE", history });
    store.dispatch(getOrganizations());
  };
}

export function zeroOrganizationPage(history) {
  return function (dispatch) {
    toggleURLQuery("page", 1, history);
    dispatch({ type: "ZERO-ORGANIZATION-PAGE", history });
    store.dispatch(getOrganizations());
  };
}

export function resetFilterParams() {
  return function (dispatch) {
    dispatch({ type: "RESET-FILTER-PARAMS" });
  };
}

export function setDefaultOrganizationFiltersToURL(history) {
  return (dispatch, getState) => {
    let { data } = getState().organizationList;
    let { params } = data;
    Object.keys(params).map((key) => {
      if (
        params[key] !== `` &&
        params[key] !== null &&
        params[key] !== undefined
      ) {
        toggleURLQuery(key, params[key], history);
      }
    });
  };
}

export function onBillableReset(value, history) {
  return function (dispatch) {
    toggleURLQuery("billable", value, history);
    toggleURLQuery("page", 1, history);
    dispatch({ type: "ON-ORGANIZATION-BILLABLE-RESET" });
    store.dispatch(getOrganizations());
  };
}

export function setDefaultSearch() {
  return function (dispatch) {
    dispatch({ type: `SET-DEFAULT-SEARCH` });
  };
}
