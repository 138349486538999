import React from "react";
import store from "../../../Store";
import { withRouter } from "react-router";
import previousSvg from "../../../assets/images/icon-previous.svg";
import leftSvg from "../../../assets/images/icon-left.svg";
import rightSvg from "../../../assets/images/icon-right.svg";
import nextSvg from "../../../assets/images/icon-next.svg";
import Loading from "../loaders/Loading";

class PaginationButtons extends React.Component {
  state = {
    loaderAt: ``,
  };

  constructor(props) {
    super(props);
    this.unlisten = props.history.listen(() => this.forceUpdate());
  }

  componentWillUnmount() {
    this.unlisten();
  }

  onClick(button, page) {
    this.setState({ loaderAt: button }, () => {
      let { fetchAction, history } = this.props;
      let e = { target: { name: `page`, value: page } };
      fetchAction && store.dispatch(fetchAction(e, history));
    });
  }

  loaderAt(button) {
    let { processing } = this.props;
    let { loaderAt } = this.state;
    return processing && loaderAt === button;
  }

  componentDidUpdate(lastProps) {
    if (lastProps.processing)
      if (!this.props.processing) this.setState({ loaderAt: `` });
  }

  render() {
    let { pages } = this.props;
    let { current_page, last_page } = pages;
    if (current_page && last_page)
      //&& ( current_page !== last_page ) )
      return (
        <ul className="pagination text-center">
          <li>
            <a
              className={`${+current_page === 1 ? `item-disabled` : ``}`}
              href="/#"
              onClick={(ev) => {
                this.onClick(`previous`, 1);
                ev.preventDefault();
              }}
            >
              {this.loaderAt(`previous`) ? (
                <Loading xs />
              ) : (
                <img src={previousSvg} alt="previous" />
              )}
            </a>
          </li>

          <li>
            <a
              className={`${+current_page === 1 ? `item-disabled` : ``}`}
              href="/#"
              onClick={(ev) => {
                this.onClick(`left`, current_page - 1);
                ev.preventDefault();
              }}
            >
              {this.loaderAt(`left`) ? (
                <Loading xs />
              ) : (
                <img src={leftSvg} alt="left" />
              )}
            </a>
          </li>

          <li className="page-info">
            {current_page} of {last_page}
          </li>

          <li>
            <a
              className={`${
                +current_page === last_page ? "item-disabled" : ""
              }`}
              href="/#"
              onClick={(ev) => {
                this.onClick(`right`, current_page + 1);
                ev.preventDefault();
              }}
            >
              {this.loaderAt(`right`) ? (
                <Loading xs />
              ) : (
                <img src={rightSvg} alt="right" />
              )}
            </a>
          </li>

          <li>
            <a
              className={`${
                +current_page === last_page ? "item-disabled" : ""
              }`}
              href="/#"
              onClick={(ev) => {
                this.onClick(`next`, last_page);
                ev.preventDefault();
              }}
            >
              {this.loaderAt(`next`) ? (
                <Loading xs />
              ) : (
                <img src={nextSvg} alt="next" />
              )}
            </a>
          </li>
        </ul>
      );
    return null;
  }
}

export default withRouter(PaginationButtons);
