import React from "react";
import { connect } from "react-redux";
import SiteWorker from "./SiteWorker";
import { toggleSiteListFilter } from "./SiteList-Actions";
import SiteHeading from "./SiteHeading";
import store from "../../../Store";
import TableHead from "../../../components/shared/table/TableHead";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import { setDashboardMenu } from "../../../routes/main-route/MainRoute-Actions";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";
import SiteTableCell from "./SiteTableCell";
import OrganizationSideMenu from "../../organization/OrganizationSideMenu";

class Site extends React.Component {
  state = {
    initialLoading: true,
  };

  componentDidMount() {
    document.querySelector("body").classList.add("organization-dashboard");
    store.dispatch(setDashboardMenu("organization"));
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    document.querySelector("body").classList.remove("organization-dashboard");
  }

  componentDidUpdate(prevProps) {
    if (this.props.getSites !== prevProps.getSites) {
      let { processing, success } = this.props.getSites;
      if (processing) {
        this.setState({ initialLoading: true });
      }

      if (success) {
        this.setState({ initialLoading: false });
      }
    }
  }

  toggleSort = (field) => {
    store.dispatch(
      toggleSiteListFilter(
        {
          // target: {name: `sort_id`, value: field}
          target: { name: `sort_by`, value: field },
        },
        this.props.match.params.id
      )
    );
  };

  render() {
    let { pages, getSites, list, params, fields, delete_site_modal } =
      this.props;
    let { processing, error } = getSites;
    if (error) {
      this.props.history.push("/");
    }

    return (
      <>
        <Page title="Sites" processing={processing}>
          <div className="page-content">
            <div className="organizations-sidebar">
              <OrganizationSideMenu processing={processing} />
            </div>
            <div className="content-section">
              <SiteHeading organization_id={this.props.match.params.id} />
              <div className="table-block table-responsive pr-60">
                <table className="table table-striped  ">
                  <TableHead
                    fields={fields}
                    params={params}
                    noActions={true}
                    onClick={this.toggleSort}
                  />
                  <tbody>
                    {processing || this.state.initialLoading ? (
                      <td colSpan={4} className="text-center">
                        <Loading />
                      </td>
                    ) : (
                      <SiteTableCell />
                    )}
                  </tbody>
                </table>
              </div>
              {list && list.length ? (
                <div
                  className="fixed-footer pr-60"
                  id="inside-organization-pagination"
                >
                  <PaginationButtons
                    pages={pages}
                    processing={processing}
                    fetchAction={toggleSiteListFilter}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <SiteWorker />
        </Page>
      </>
    );
  }
}

function mapStateToProps(state) {
  let {
    userDetail,
    siteList,
    getSites,
    getOrganizationStatus,
    getLoggedInUser,
  } = state;
  let { params, fields, modal, delete_site_modal } = siteList.data;
  let role =
    getLoggedInUser &&
    getLoggedInUser.success &&
    getLoggedInUser.success.data &&
    getLoggedInUser.success.data.data &&
    getLoggedInUser.success.data.data.role &&
    getLoggedInUser.success.data.data.role.slug;

  let { success } = getSites;

  let org_status = getOrganizationStatus && getOrganizationStatus.success;

  let list = success && success.data && success.data.data.data;

  let current_page = success && success.data && success.data.data.current_page;
  let last_page = success && success.data && success.data.data.last_page;
  let total = success && success.data && success.data.data.total;
  let pages = { current_page, last_page, total };

  return {
    getSites,
    list,
    userDetail,
    siteList,
    params,
    fields,
    modal,
    getLoggedInUser,
    pages,
    getOrganizationStatus,
    org_status,
    role,
    delete_site_modal,
  };
}
export default connect(mapStateToProps)(Site);
