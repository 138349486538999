import React from "react";
import store from "../../../Store";
import withRouter from "react-router/withRouter";
import getSites from "../../../ajax/actions/sites/sites_get";
import { resetSearchParams, setOrganizationIDSite } from "./SiteList-Actions";
import getOrganization from "../../../ajax/actions/organization/organization_get";
import { setOrganizationDashboardData } from "../../organization/OrganizationDashboard-Action";
import { connect } from "react-redux";
import getOrganizationStatus from "../../../ajax/actions/organization/organization_status_get";
import getOrganizationsBlock from "../../../ajax/actions/organization/organizations_block_get";

class SiteWorker extends React.Component {
  componentDidMount() {
    let id = this.props.match.params.id;
    store.dispatch(getOrganizationStatus(this.props.match.params.id));
    store.dispatch(setOrganizationIDSite(id));
    store.dispatch(getSites(id));
    store.dispatch(getOrganization(id));
    store.dispatch(getOrganizationsBlock());
    this.periodicFetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.getOrganization !== prevProps.getOrganization) {
      let { success } = this.props.getOrganization;
      if (success) {
        store.dispatch(setOrganizationDashboardData());
        store.dispatch(resetSearchParams());
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  periodicFetch() {
    this.timer = setInterval(
      () =>
        !this.props.getOrganizationStatus.processing &&
        store.dispatch(getOrganizationStatus(this.props.match.params.id)),
      10000
    );
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  let { getOrganization, getOrganizationStatus, getSites } = state;

  return { getOrganization, getOrganizationStatus, getSites };
}
export default withRouter(connect(mapStateToProps)(SiteWorker));
