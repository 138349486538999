import { SiteListState } from "./SiteList-State";

export default function siteListReducer(state = SiteListState, action) {
  let { data } = { ...state };
  let { name, value, id, isOpen } = action;

  switch (action.type) {
    case "TOGGLE-SITE-LIST-FILTER":
      data.params[name] = value;
      // if ( name === `sort_id` ) {
      //   if (data.params.sort_id === value) {
      //     data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
      //   }
      // }
      if (name === `sort_by`) {
        if (data.params.sort_by === value) {
          data.params.sort_order =
            data.params.sort_order === `desc` ? `asc` : `desc`;
        }
      }
      return { ...state, data };

    case "RESET-SEARCH-PARAMS":
      data.params.q = "";
      data.params.page = 1;
      data.payload.initialLoading = true;
      return { ...state, data };

    case "SET-ORGANIZATIONID-SITE":
      data.organization_id = id;
      return { ...state, data };

    case "TOGGLE-SITE-FORM-MODAL":
      if (data.modal.mode) {
        // if open, close
        data.modal = { mode: `` };
      } else {
        // if close, open
        // data.modal.id = id || ``;
        data.modal.mode = true;
      }
      return { ...state, data };

    default:
      return state;
  }
}
