import { OrganizationListState } from "./OrganizationList-State";

export default function organizationListReducer(
  state = OrganizationListState,
  action
) {
  let { data } = { ...state };
  let { name, value } = action;
  switch (action.type) {
    case "TOGGLE-ORGANIZATION-LIST-FILTER":
      data.params[name] = value;
      if (name === `sort_by`) {
        if (data.params.sort_by === value) {
          data.params.sort_order =
            data.params.sort_order === `desc` ? `asc` : `desc`;
        }
      }
      let toggle = true;
      if (toggle) {
        if (name === "limit") {
          data.params.page = 1;
        }
        data.params[name] = value;
      }
      return { ...state, data };

    case "ON-ORGANIZATION-BILLABLE-CHANGE":
      data.params[name] = value;
      data.params.page = 1;
      if (name === `sort_by`) {
        if (data.params.sort_by === value) {
          data.params.sort_order =
            data.params.sort_order === `desc` ? `asc` : `desc`;
        }
      }
      data.params[name] = value;
      return { ...state, data };

    case "ON-DEFAULT-PAGE":
      data.params.page = 1;
      data.params.sort_order = `desc`;
      data.noSites = false;
      return { ...state, data };

    case "ZERO-ORGANIZATION-PAGE":
      data.params.page = 1;
      data.params.sort_order = `asc`;
      data.noSites = true;
      return { ...state, data };

    case "RESET-FILTER-PARAMS":
      data.noSites = false;
      data.params.page = 1;
      data.params.sort_order = `desc`;
      return { ...state, data };

    case "ON-ORGANIZATION-BILLABLE-RESET":
      delete data.params.billable;
      data.params.page = 1;
      return { ...state, data };

    case "SET-DEFAULT-SEARCH":
      data.params.q = ``;
      return { ...state, data };

    default:
      return state;
  }
}
