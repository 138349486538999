import React from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";
import SiteDashboardWorker from "./SiteDashboardWorker";
import Page from "../../components/shared/pages/Page";
import store from "../../Store";
import { setDashboardMenu } from "../../routes/main-route/MainRoute-Actions";
import Sftp from "./sftp-database/list/SftpList";
import Domain from "./domain/list/Domain";
import Cache from "./cache/list/Cache";
import FeatureBlock from "./feature-block/list/FeatureBlock";
import Backup from "./backup/list/BackupList";
import SiteDashboardHeader from "./SiteDashboardHeader";
import Logs from "./logs/list/LogsList";
import OrganizationSideMenu from "../organization/OrganizationSideMenu";
import { changeLink } from "./SiteDashboard-Action";
import SiteDashboardLinks from "./SiteDashboardLinks";
import AdminOrganization from "./admin-organization/AdminOrganization";
import SSL from "./ssl/list/SSL";
import Redirects from "./redirects/list/Redirects";
import Plan from "./plan/list/PlanList";

class SiteDashboard extends React.Component {
  componentDidMount() {
    document.querySelector("body").classList.add("organization-dashboard");
    store.dispatch(setDashboardMenu("organization"));
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    document.querySelector("body").classList.remove("organization-dashboard");
  }

  onCheckedChange = (data) => {
    store.dispatch(changeLink(data));
  };

  render() {
    let { match, getSite } = this.props;

    let { processing } = getSite;
    let { url } = match;

    return (
      <>
        <Page title="Sites">
          <div className="page-content">
            <div className="organizations-sidebar">
              <OrganizationSideMenu processing={processing} />
            </div>
            <div className="content-section">
              <SiteDashboardHeader
                title="Sites"
                organization_id={this.props.match.params.org_id}
                processing={processing}
              />

              <SiteDashboardWorker
                organization_id={this.props.match.params.org_id}
              />
              <div className="settings-block">
                <SiteDashboardLinks url={url} processing={processing} />
                <Switch>
                  <Route
                    exact
                    path={
                      "/organizations/:id([0-9]{0,9})/sites/:id([0-9]{0,9})/launchpad"
                    }
                  >
                    <div className="settings-section">
                      <div className="single-setting">
                        <div className="setting-content" id="sftp">
                          <div className="setting-content-wrapper">
                            <Sftp />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Route>
                  <Route
                    exact
                    path={
                      "/organizations/:id([0-9]{0,9})/sites/:id([0-9]{0,9})/launchpad/admin"
                    }
                  >
                    <div className="settings-section">
                      <div className="single-setting">
                        <div className="setting-content" id="sftp">
                          <div className="setting-content-wrapper">
                            <Sftp />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Route>
                  <Route
                    exact
                    path="/organizations/:id([0-9]{0,9})/sites/:id([0-9]{0,9})/domain"
                  >
                    <div className="settings-section">
                      <div className="single-setting">
                        <div className="setting-content" id="domain">
                          <div className="setting-content-wrapper">
                            <Domain />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Route>
                  <Route
                    exact
                    path="/organizations/:id([0-9]{0,9})/sites/:id([0-9]{0,9})/redirects"
                  >
                    <div className="settings-section">
                      <div className="single-setting">
                        <div className="setting-content" id="redirects">
                          <div className="setting-content-wrapper pt-0">
                            <Redirects />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Route>
                  <Route
                    exact
                    path="/organizations/:id([0-9]{0,9})/sites/:id([0-9]{0,9})/backup"
                  >
                    <div className="settings-section">
                      <div className="single-setting">
                        <div className="setting-content" id="backup">
                          <div className="setting-content-wrapper">
                            <Backup />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Route>
                  <Route
                    exact
                    path="/organizations/:id([0-9]{0,9})/sites/:id([0-9]{0,9})/featureblock"
                  >
                    <div className="settings-section" id="tools-sections">
                      <div className="single-setting">
                        <div className="setting-content" id="keplertools">
                          <div className="setting-content-wrapper">
                            <FeatureBlock />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Route>
                  <Route
                    exact
                    path="/organizations/:id([0-9]{0,9})/sites/:id([0-9]{0,9})/cache"
                  >
                    <div className="settings-section">
                      <div className="single-setting">
                        <div className="setting-content" id="cache">
                          <div className="setting-content-wrapper pt-0">
                            <Cache />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Route>
                  <Route
                    exact
                    path="/organizations/:id([0-9]{0,9})/sites/:id([0-9]{0,9})/ssl"
                  >
                    <div className="settings-section">
                      <div className="single-setting">
                        <div className="setting-content" id="cache">
                          <div className="setting-content-wrapper pt-0">
                            <SSL />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Route>
                  <Route
                    exact
                    path="/organizations/:id([0-9]{0,9})/sites/:id([0-9]{0,9})/adminorg"
                  >
                    <div
                      className="settings-section"
                      id="settings-sections-bulk"
                    >
                      <AdminOrganization />
                    </div>
                  </Route>
                  <Route
                    exact
                    path="/organizations/:id([0-9]{0,9})/sites/:id([0-9]{0,9})/logs"
                  >
                    <div className="settings-section">
                      <div className="single-setting">
                        <div className="setting-content" id="Logs">
                          <div className="setting-content-wrapper">
                            <Logs />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Route>
                  <Route
                    exact
                    path="/organizations/:id([0-9]{0,9})/sites/:id([0-9]{0,9})/plan"
                  >
                    <div className="settings-section">
                      <div className="single-setting">
                        <div className="setting-content" id="Logs">
                          <div className="setting-content-wrapper">
                            <Plan />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </Page>
      </>
    );
  }
}

function mapStateToProps(state) {
  let {
    route,
    userDetail,
    organizationDashboard,
    getOrganization,
    getCredentials,
    getSite,
    siteDashboard,
  } = state;
  let role = route && route.data.user && route.data.user.role.slug;
  let { link } = siteDashboard.data;
  return {
    userDetail,
    organizationDashboard,
    getOrganization,
    getCredentials,
    getSite,
    role,
    link,
  };
}

export default withRouter(connect(mapStateToProps)(SiteDashboard));
